@import 'assets/css/index';

.main-page-content {
  padding-top: 85px;
}

.content {
  margin-top: 5px;
  // padding: 0 15px;
  overflow: hidden;
}

.modal {
  padding-top: 15px !important;
  padding-right: 15px !important;
  display: block !important;
  background-color: #263b4eeb !important;
  opacity: 1 !important;

  &.left .modal-dialog,
  &.right .modal-dialog {
    position: relative;
    max-width: none !important;
    height: 100%;
    margin-top: 0;
    padding-left: 346px;
    transform: translate3d(0%, 0, 0) !important;
  }

  &.left .modal-content,
  &.right .modal-content {
    width: 550px;
    height: 100%;
    overflow-y: auto;
    border: 0;
    border-radius: 0;
  }

  &.left .modal-body,
  &.right .modal-body,
  &.left .modal-title,
  &.right .modal-title {
    padding: 15px;
  }

  &.right.fade {
    .modal-dialog {
      right: 0;
      padding-top: 0 !important;
      transition: opacity 0.3s linear, right 0.3s ease-out;
    }

    &.in .modal-dialog {
      right: 0;
    }
  }
}

.modal-header {
  background-color: #fafafa;
  border-bottom-color: #eeeeee;
}

#board {
  margin-top: 5px;
  padding-bottom: 50px;
  margin-bottom: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  user-select: none;

  .list-body {
    max-height: 260px;
    min-height: 260px;
  }
}

.list-wrapper {
  margin-right: 8px;
  height: 100%;

  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  white-space: nowrap;
}

.list {
  position: relative;
  width: 391px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ebecf0;
  border-radius: 3px;
  box-sizing: border-box;
  white-space: normal;
}

.list-header {
  position: relative;
  min-height: 20px;
  flex: 0 0 auto;
}

.list-body {
  max-height: 400px;
  min-height: 230px;
  padding-top: 10px;
  padding: 16px;
  padding-top: 10px;
  background: #f4f8fc;
  overflow-y: auto;

  .playlist-resource {
    padding: 9px 4px;
    background: #fff 0 0 no-repeat padding-box;
    border-left: 2px solid #084892;
    border-radius: 5px;
    box-shadow: 1px 1px 2px #7e7e7e1a;
    opacity: 1;
    margin-bottom: 8px;

    .timestamp {
      position: absolute;
      bottom: 0;
      right: 20px;
      text-align: right;
    }

    .activity-thumb-wrapper {
      width: 36px;
      height: 20px;
      margin-right: 8px;
    }

    .activity-thumb-content {
      max-width: 213px;
      height: 53px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      float: left;

      a {
        margin-top: -3px;
        display: block;
      }

      .social-icons-tray {
        a {
          display: inline-block;
        }

        img {
          width: 14px;
          margin-right: 10px;
        }
      }
    }

    .activity-thumb {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      border: 1px solid #cccc;
    }

    i {
      font-size: 14px;
      color: #757575;
    }

    button {
      box-shadow: none;
    }

    .title {
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0;
        color: $mine-shaft;
        opacity: 1;
        text-transform: capitalize;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.u-fancy-scrollbar {
  overflow-scrolling: touch;
  transform: translateZ(0);
}

.list-cards {
  min-height: 0;
  margin: 0 4px;
  padding: 0 4px;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
}

.card-composer-container {
  min-height: 38px;
  max-height: 38px;
  display: flex;
  justify-content: space-between;
}

.open-card-composer {
  position: relative;
  margin: 2px 0 8px 8px;
  padding: 4px 8px;
  display: block;
  flex: 1 0 auto;
  border-radius: 3px;
  color: #5e6c84;
  text-decoration: none;
  user-select: none;

  .icon-add {
    margin-right: 2px;
    color: #6b778c;
  }
}

.post {
  .post-title {
    & > label {
      display: block;
    }
  }
}

.program-tile {
  height: 100%;
  min-height: 450px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  .button-bottom {
    margin: -10px;
    display: flex;

    .fa {
      padding-right: 3px;
      font-size: 12px;
    }

    a {
      width: 33.33%;
      padding: 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ece9e9;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #5952c6;
      letter-spacing: 0;
      opacity: 1;

      &:hover {
        background-color: #5952c6;
        color: #fff;
        text-decoration: none;

        button span,
        button svg {
          color: #fff !important;
        }
      }
    }
  }

  .program-thumb {
    height: 200px;
    overflow: hidden;

    a {
      width: 100%;
      height: 100%;
      display: block;
    }

    .project-thumb {
      min-height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      margin: auto;
      display: block;
    }
  }

  .program-content {
    padding: 5px;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin-top: 10px;

      a {
        font-family: $rubik, sans-serif;
        font-size: 20px;
        font-weight: 300;
        color: $fun-blue-1;

        &:hover {
          text-decoration: none;
        }
      }
    }

    i {
      font-size: 20px;
    }

    button {
      box-shadow: none;
    }

    .program-creator {
      font-size: 14px;
      color: #aaa;

      a {
        color: inherit;
      }
    }

    .lessons-duration {
      margin-top: 10px;

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 134%;
        color: $dove-gray;
        letter-spacing: 0;
        text-align: justify;
      }

      .lessons {
        display: inline-block;

        .icon {
          width: 13px;
          height: 13px;
          margin-top: 3px;
          margin-right: 8px;
          display: block;
          background: url(../../assets/images/program-lesson-icon.png) no-repeat center;
          float: left;
        }
      }

      .duration {
        margin-left: 15px;
        display: inline-block;

        .icon {
          width: 13px;
          height: 13px;
          margin-top: 3px;
          margin-right: 8px;
          display: block;
          background: url(../../assets/images/program-duration-icon.png) no-repeat center;
          float: left;
        }
      }
    }

    .go-to-playlist {
      position: absolute;
      bottom: 10px;
      right: 30px;
      margin-top: 25px;
      text-align: right;
    }
  }

  &:hover .button-bottom {
    display: flex;
  }
}

.program-page-title {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .project-page-settings {
    display: flex;
    align-items: center;

    .sort-project-btns {
      width: 150px;
      height: 35px;
      margin-bottom: 10px;
      margin-right: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 60px;
      overflow: hidden;

      .sort-btn {
        height: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #ccc;
        cursor: pointer;

        &:last-child {
          border-right: none;
        }
      }

      .sort-btn.active {
        background-color: #6e6d6d;

        svg {
          color: #fff;
        }
      }
    }
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    background: url(../../assets/images/my-projects-icon.png) no-repeat 0 9px;
    font-family: $rubik, sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #607a9b;
  }

  .btn-top-page {
    width: -moz-fit-content;
    width: 150px;
    height: 35px;
    margin-bottom: 10px;
    /* padding: 3px 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5952c6;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    cursor: pointer;

    .fa {
      padding-right: 5px;
    }
  }
}

.project-sample-share {
  width: 100%;

  .btn-back {
    position: relative;
    z-index: 11;
    width: -moz-fit-content;
    width: 70px;
    height: 35px;
    margin: 0 10px 10px 0;
    /* padding: 3px 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    background-color: $fun-blue-1;
    border-radius: 20px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .container {
    max-width: 100%;
  }

  .full-width-share {
    margin-top: 20px;
    clear: both;
  }
}

.main-tabs {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 16px !important;

  .nav-item {
    position: relative;
    margin-right: 5px;
    padding: 6px 24px;
    background-color: #fff !important;
    font-size: 14px;
    font-weight: 400;
    color: $curriki-main-blue !important;
  }

  .nav-item.active {
    background-color: #6e6d6d !important;
    color: #fff !important;

    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid #6e6d6d;
      border-bottom-color: #6e6d6d;
      transform: translate(-50%);
    }
  }
}

.main-content-wrapper {
  width: 100%;
  padding-top: 80px;
  // padding-bottom: 45px;
  // display: flex;
  float: left;

  .sidebar-wrapper {
    position: fixed;
    z-index: 999;
    width: 136px;
    height: 100%;
    padding-bottom: 100px;
    float: left;
    background: #fff;
    border-right: 1px solid #dcdcdc;

    //.collapse-button {
    //  position: absolute;
    //  top: 16px;
    //  right: 0;
    //  padding: 6px 15px;
    //  background: #cbcbcb;
    //  border-radius: 20px 0 0 20px;
    //
    //  &:hover {
    //    background: #999999;
    //    cursor: pointer;
    //  }
    //}

    //.bread-crumb {
    //  position: absolute;
    //  top: 0;
    //  left: 100%;
    //  width: calc(100vw - 100%);
    //  padding: 17px 0 8px 55px;
    //  border-bottom: solid 1px #CBCBCB;
    //
    //  span {
    //    margin: 0 8px;
    //    font-size: 12px;
    //    text-transform: capitalize;
    //
    //    &.parent {
    //      color: #5250C1;
    //      text-decoration: underline;
    //    }
    //  }
    //
    //  svg {
    //    //margin: 0 10px 0 22px;
    //    margin: 0 13px 0 17px;
    //  }
    //}
  }

  .content-wrapper {
    margin-left: 300px !important;
    margin-bottom: 65px;
  }
}

.h5p-content-wrap {
  .h5p-iframe-wrapper {
    & > iframe {
      width: 100%;
      // min-height: 600px !important;
    }
  }

  .h5p-fullscreen {
    & > iframe {
      height: 100% !important;
    }
  }
}

.preview-box {
  min-height: 320px;
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #000;

  & > a {
    height: 159px;
    display: block;
  }

  img {
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    display: block;
  }

  h5 {
    margin-left: 23px;
    margin-right: 10px;

    a {
      color: #000;
    }

    text-align: center;
  }
}

.next-prev-link {
  font-size: 35px;
  display: inline-block;
  margin: 0 8px;
}

.dropdown-menu {
  a {
    cursor: pointer;
  }
}

.validation-error {
  color: red;
}

// .dropdown-menu {
//     left: -50px !important;
// }

.react-h5-type {
  display: none;
}

/* NEW CSS which applies to whole application*/
/* CSS Document */
// TODO: remove after check, it generates a lot of junk styles
html {
  margin: 0;
  padding: 0;
  border: 0;
}

body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
dialog,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1.5;
  background: white;
}

aside.sidebar-all {
  min-height: 100%;
  margin-top: 32px;
  overflow: auto;
  height: 100%;
  padding-bottom: 50px;
}
aside.sidebar-all:first-child {
  border-top: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  float: none !important;
}

table,
th,
td {
  vertical-align: middle;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}

blockquote,
q {
  quotes: unset;
}

a img {
  border: none;
}

:focus {
  outline: 0;
}

* {
  box-sizing: border-box;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.form-control {
  width: 100%;
  height: 60px;
  padding: 4px 12px 4px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #3a466d;
  font-size: 16px;
  color: #7b7b7b;
  font-family: $rubik, sans-serif;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7b7b7b;
  font-family: $rubik, sans-serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #7b7b7b;
  font-family: $rubik, sans-serif;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #7b7b7b;
  font-family: $rubik, sans-serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #7b7b7b;
  font-family: $rubik, sans-serif;
}

body {
  background: #eeeeee;
  font-family: $rubik, sans-serif;
  font-weight: normal;
  font-size: 16px;
  position: relative;
  z-index: 0;
  line-height: 26px;
  color: #717173;
}

.leftmenu {
  width: 290px;
  float: left;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  height: 100vh;
  transition: all ease-out 0.9s !important;
  top: 0;
}

.navbar-link li .dropdown-menu {
  position: absolute;
  left: auto;
  padding: 0;
  right: 15px;
  margin: 0;
  // margin-top: 3px;
  text-align: left;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 10px 10px;
  width: 405px;
  background: #ffffff;
}

/* start top header */
.mobile-links {
  display: none !important;
}

.top-header {
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0 5px 6px #00000026;
  z-index: 111;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  top: 0;
  padding: 0 32px;
}

.closebtn {
  width: 85px;
  display: inline-block;
  text-align: center;
  padding: 28px 0;
  border-right: 1px solid #dbdbdb;
}

.top-info > li > a,
.dropbtn {
  display: block;
  border-radius: 100%;
}

.top-info {
  margin: 0 -15px;
}

.top-info li {
  padding-left: 32px;
}

.top-info > li:first-child > a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  svg {
    color: $fun-blue-1;
    font-size: 28px;
  }

  p {
    padding-top: 2px;
  }
}

.top-header-right {
  padding-right: 40px;
}

.top-info > li > a:hover {
  //  background: #1a73e8;
  //border-color: #1a73e8;
}

.top-info > li > a:hover img {
  // filter: brightness(0) invert(1);
}

/* css for dropdown menu */
.navbar-link li .dropdown-menu.user-dropdown {
  min-width: 230px;
  width: auto;
}

.user-dropdown li {
  border-bottom: 1px solid #a6a6a6;
  display: block;
  text-align: center;
  width: 100%;
  border-radius: 0;
  color: $mine-shaft;
  padding: 15px !important;
}

.user-dropdown li a {
  height: auto;
  line-height: 100%;
  border: 0;
  display: block;
}

.top-info li .user-dropdown li a:hover {
  background: none;
}

/* .navbar-link .top-info {
    position: relative
} */

.menuopen .leftmenu {
  width: 310px;
  overflow: visible;
}

.menuopen .leftmenu:before {
  display: none;
}

body.menuopen {
  position: fixed;
  width: 100%;
}

body.menuopen:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(9, 24, 73, 0.94);
  z-index: 11;
}

.menuopen .leftmenu a.logo {
  display: inline-block;
  opacity: 1;
  transition: all ease-out 0.3s !important;
}

a.logo {
  max-width: 275px;
  float: left;
  margin: 35px 0 0 20px;
  transition: all ease-out 0.3s !important;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 40px;
}

a.logo img {
  width: 100%;
}

/*navigation styles start*/
nav {
  width: 290px;
  clear: both;
  // float: left;
  margin: 130px 0 0 0;
  padding: 0 25px 0 30px;
}

nav ul {
  width: 100%;
  clear: both;
  float: left;
}

nav ul li {
  width: 100%;
  clear: both;
  float: left;
  text-transform: capitalize;
  padding: 15px 0;
}

nav ul li a {
  font-size: 16px;
  color: #607a9b;
  font-family: $rubik, sans-serif;
  line-height: 100%;
  transition: all 0.5s linear;
}

nav ul li:not(:last-child) {
  border-bottom: 1px solid #e8e8e8;
}

nav ul li i {
  font-size: 13px;
  color: #607a9b;
  padding-right: 10px;
}

nav ul li a img {
  display: inline-block;
  padding-right: 10px;
  vertical-align: baseline;
}

nav > ul > li > a:hover,
nav > ul > li.active > a,
.submenu li a:hover,
.submenu li.active a,
.submenu li a:hover i,
.submenu li.active i,
nav > ul > li > a:hover i,
nav > ul > li.active a i {
  color: #1a73e8;
}

nav > ul > li > a:hover img,
nav > ul > li.active > a img {
  filter: invert(70%) sepia(23%) saturate(1975%) hue-rotate(192deg) brightness(108%) contrast(100%);
}

.show {
  display: block;
}

.submenu li i {
  padding-right: 2px;
}

body.menuopen nav ul li.haschild a:after {
  position: absolute;
  opacity: 1;
  transition: all ease-out 3.5s !important;
}

/*nav ul li ul.submenu1 li ul.submenu2 {display: none}*/
.submenu {
  padding-left: 25px;
}

.submenu li {
  text-transform: initial;
}

.submenu li a {
  background: #fafafa;
  border: 1px dashed #bfbfbf;
  padding: 3px 10px;
  border-radius: 2px;
  font-size: 14px;
}

/*end*/

/*.leftcol_btndiv {width: 100%; float: left; clear: both; border-top: 1px solid #e5e5e5; padding: 30px 0 0 0; margin:30px 0 0 0}
.leftcol_btndiv a{width:170px; border: 0 solid #e5e5e5; text-align: left; color: transparent; font-size:14px; clear: both; display: block; margin: 10px 0 0 40px; padding: 10px 20px 10px 0;text-transform: uppercase; text-decoration: none; border-radius: 4px}
.menuopen .leftcol_btndiv a{width:170px; border: 1px solid #e5e5e5; text-align: left; color: #999999; font-size:16px; clear: both; display: block; margin: 10px 0 0 40px; padding: 10px 20px;text-transform: uppercase; text-decoration: none}
.leftcol_btndiv a img{width: 16px; margin: -2px 10px 0 0; -webkit-filter: invert(0.3); -moz-filter: invert(0.3); -ms-filter: invert(0.3); filter: invert(0.3);}
.leftcol_btndiv a:hover img{-webkit-filter: invert(0); -moz-filter: invert(0); -ms-filter: invert(0); filter: invert(0);}
*/
.container {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.container.flex-div:after,
.container.flex-div:before {
  display: block;
}

.flex-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* HEADER CSS */
header {
  width: 100%;
  clear: both;
  background: #fff;
  position: relative;
  z-index: 1000;
  .flex-div {
    flex-wrap: nowrap;
  }
}

.menuoverlay:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9;
}

.dropdown-menu li a {
  display: block;
  width: 100%;
  border-radius: 0;
  color: $mine-shaft;
  text-align: left;
}

/* .dropdown-menu li:hover>a {
  color: #ffffff;
} */

.dropdown-menu li {
  padding: 0;
  color: $mine-shaft;
  text-align: left;
}

.top-header-right {
  position: relative;
  padding: 0 30px;
  color: $mine-shaft;
  text-align: left;
}

.notify-icon {
  width: 60px;
  display: inline-block;
  vertical-align: top;
  color: $curriki-main-blue;
}

.notify-description {
  width: calc(100% - 65px);
  display: inline-block;
}

.nav-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0;
  color: $curriki-main-blue;
  opacity: 1;
}

.navmenu li {
  padding: 30px 35px;
  border-bottom: 1px solid #eee !important;
}

.navmenu li:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.notify-description p {
  padding-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $mine-shaft;
  opacity: 0.8;
  white-space: initial;
}

.notify-icon i {
  font-size: 32px;
  color: #607a9b;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  clear: both;
  width: 100%;
}

.togglebtn {
  position: fixed;
  left: 23px;
  top: 117px;
  display: none;
  text-align: center;
  width: 55px;
  height: 55px;
  padding: 20px 0;
  border: 0;
  cursor: pointer;
  z-index: 1001;
}

.togglebtn span {
  position: relative;
  display: inline-block;
  margin-top: 7px;
  vertical-align: top;
}

.togglebtn span::before,
.togglebtn span::after {
  content: '';
  position: absolute;
  left: 0;
}

.togglebtn span,
.togglebtn span:before,
.togglebtn span:after {
  background-color: #929292;
}

.togglebtn span::before {
  top: -7px;
}

.togglebtn span::after {
  top: 7px;
}

.togglebtn span,
.togglebtn span:before,
.togglebtn span:after {
  transition: all 0.9s ease-in-out;
  width: 25px;
  height: 2px;
}

header.head_mobile {
  display: none;
}

.title-md {
  font-size: 22px;
  color: #21448e;
  font-family: $rubik, sans-serif;
  font-weight: 600;
  line-height: 100%;
  padding-bottom: 18px;
  position: relative;
}

.header_mobilxs {
  display: none;
}

.scrollTop {
  position: fixed;
  right: 0;
  bottom: 85px;
  border-radius: 0;
  opacity: 0;
  height: 40px;
  width: 40px;
  line-height: 36px;
  z-index: 10;
  color: #fff;
  background: #1a73e8;
  text-align: center;
  font-size: 30px;
}

/* START MAIN CONTENT CSS */

.pointer-cursor {
  cursor: pointer;
}

.site-container {
  width: calc(100% - 290px);
  margin: 100px 0 0 290px;
  transition: all ease-out 0.3s !important;
}

.site-container-preview {
  width: calc(100% - 158px);
  transition: all ease-out 0.3s !important;
  margin-left: 158px;
  margin-top: 32px;
  .container {
    max-width: 100%;
    margin: 0 auto;
    background: #ffffff;
    padding: 24px 32px;
    .project-details {
      display: flex;
      .project-title-desc {
        // width: 700px;
        margin-left: 16px;
        margin-right: 24px;
        @media (max-width: 1024px) {
          width: 400px;
        }
        .project-title {
          margin-top: 16px;
          h2 {
            color: rgb(8, 72, 146) !important;
            font-weight: 500;
            font-family: 'rubic';
            font-size: 32px;
            line-height: 36px;
            font-style: normal;
            margin: 0;
            padding: 0;
          }
        }
        .project-description {
          margin-top: 16px;
          p {
            color: #515151 !important;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.site-container .container {
  max-width: 1551px;
  margin: 0 auto;
  background: #ffffff;
  padding: 32px;
}

.addtop::after {
  content: none;
}

.drp-heading {
  color: #607a9b;
  font-size: 26px;
}

.dropdown-menu li:last-child {
  border-bottom: 0;
}

a > li:hover {
  background-color: #e8e7f1;
}

a:hover {
  text-decoration: none;
}

.menuLinks {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none;
}

/* ============ start media query ============ */

@media screen and (max-width: 1800px) {
  /* .container {
        padding: 0 15px;
    } */
}

@media screen and (max-width: 992px) {
  .menu_link {
    float: none;
    padding: 0;
    position: fixed;
    top: 0;
    height: 100%;
    max-width: 1024px;
    margin: 0;
    overflow-y: auto;
    z-index: 1000;
    left: -290px;
    transform: translateX(0%);
    display: block;
    background: #fff;
    transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .menu_link.active {
    transform: translateX(100%);
  }

  html.menuhidden body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 118, 188, 0.87);
    height: 100%;
    z-index: 1;
  }

  .togglebtn {
    right: 15px;
    top: 24px;
    display: block;
    left: auto;
    padding: 0;
    height: 30px;
  }

  .tophd_left {
    padding: 0;
  }

  .navbar-link .top-info {
    margin-right: 50px;
  }

  .site-container {
    width: 100%;
    margin: 0;
  }

  .leftmenu {
    width: 0;
  }

  .top-header {
    position: relative;
    padding: 15px;
  }

  /* .top-info  li:not(:first-child){display: none;}*/
  .top-header-right {
    padding-right: 0;
  }

  .top_logo {
    padding-left: 0;
  }

  .header_mobilxs {
    display: block;
  }

  .head_list {
    width: 300px;
    justify-content: space-around;
    margin: 0 0 0 auto;
  }

  .head_list li {
    padding: 25px 0;
  }

  .togglebtn.active span {
    background-color: transparent;
  }

  .togglebtn.active span::before {
    transform: rotate(-135deg);
    top: 0;
  }

  .togglebtn.active span::after {
    top: 0;
    transition-delay: 0.09s;
    transform: rotate(135deg);
  }

  .togglebtn.active {
    left: auto;
    right: 15px;
    background: #ffffff;
    border-radius: 100%;
  }

  footer {
    position: relative;
    width: 100%;
  }

  .navbar-link .navmenu {
    transform: none;
    width: 100%;
    position: relative;
    max-width: 100%;
    left: auto;
    box-shadow: none;
    margin: 1px 0;
    background: #e5f4fb;
    min-width: inherit;
    border: 0;
    padding: 0;

    li {
      padding: 10px;
    }
  }

  .navbar-link nav > ul > li.has-sub {
    position: static;
  }

  .searchxs {
    display: none;
    position: relative;
    padding-top: 30px;
  }

  .searcg_toggle {
    display: block;
  }

  .closesearch {
    position: absolute;
    right: 35px;
    top: 0;
  }

  .search-block {
    margin-right: 20px;
    margin-bottom: 0;
    width: 275px;
  }

  .top-info > li {
    padding: 0 10px;
  }

  header {
    min-height: auto;
  }

  .search-submit {
    right: 0;
  }

  .main-page-content {
    padding-top: 0;
  }
}

@media (max-width: 860px) {
  .tophd_left {
    width: 180px;
  }

  .search-block {
    width: 250px;
  }

  a img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .top-header-right {
    width: 100%;
    background-color: #eeeeee;
    padding: 15px;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
  }

  .top-header {
    padding: 0;
    box-shadow: none;
  }

  .tophd_left {
    margin-bottom: 0;
    padding: 15px;
  }

  .navbar-link .top-info {
    margin-right: 0;
  }
}

@media (max-width: 580px) {
  .navbar-link li .dropdown-menu {
    display: none;
  }

  .navbar-link li .dropdown-menu {
    width: 100%;
    position: relative;
    padding-bottom: 0;
    left: auto;
  }

  .search-block {
    display: none;
  }

  .togglebtn {
    right: inherit;
    top: 90px;
    display: block;
    left: 15px;
    width: 35px;
  }

  .navbar-link {
    width: 100%;
  }

  .top-info.flex-div {
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    width: calc(100% - 50px);
  }

  .top-info.flex-div li:first-child {
    position: absolute;
    top: 0;
    padding: 0 !important;
    margin-top: -55px;
    right: 15px;
    width: auto;
  }

  .top-info.flex-div li:first-child a {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }

  .top-info li a,
  .dropbtn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 0;
    background: transparent;
  }

  .mobile-links {
    display: inline-block !important;
  }

  .top-info li {
    display: inline-flex;
  }

  .top-info > li {
    padding: 0 0 0 2em !important;
  }

  .top-info.flex-div li:nth-child(2) {
    order: 2;
  }

  .top-info.flex-div li.mobile-links {
    order: 1;
  }

  .top-info.flex-div li:nth-child(4) {
    order: 3;
  }
}

@media (max-width: 480px) {
  .top-info li:not(:first-child) {
    padding: 0 5px;
    display: inline-flex !important;
    text-align: center;
    justify-content: center;
  }

  .tophd_left {
    width: 180px;
  }
}

@media (max-width: 380px) {
  .tophd_left {
    width: 180px;
  }

  .top_logo img {
    max-width: 100%;
  }
}

#curriki-h5p-wrapper iframe {
  width: 100%;
  height: 100%;
}

//project-each-view
.index-text {
  margin: 0 5px 0 15px;
}

.board-custom {
  .list-header-name {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0;
    color: #0a0708;
    opacity: 1;
    padding: 15px 25px;
    text-transform: capitalize;
  }

  span {
    height: auto;
    padding: 0;
    max-width: 170px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list {
    width: 249px;
  }
}

#custom_accordion {
  margin-top: 0 !important;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto;
  }

  .slick-prev {
    background: #ffffff 0 0 no-repeat padding-box;
    //box-shadow: 1px 1px 5px 0 #000000;
    border-radius: 50%;
    left: 6px;
    border: 1px solid #ccc;
    width: 22px;
    height: 22px;
    z-index: 1;
    overflow: hidden;

    &:before {
      //content: "\f053";
      display: block;
      margin-left: -2px;
      //font-family: FontAwesome;
      font-size: 24px;
      text-align: center;
      color: #333;
    }
  }

  .slick-track {
    margin: initial;
  }

  .slick-next {
    background: #ffffff 0 0 no-repeat padding-box;
    //box-shadow: 1px 1px 5px 0 #000000;
    border-radius: 50%;
    right: -15px;
    border: 1px solid #ccc;
    width: 22px;
    height: 22px;
    overflow: hidden;

    &:before {
      //content: "\f054";
      display: block;
      margin-left: -2px;
      //font-family: FontAwesome;
      font-size: 24px;
      color: #333;
      text-align: center;
    }
  }

  .slick-prev {
  }

  .panel {
    padding: 8px 20px 30px 0;
    display: none;
    background-color: white;
    overflow-x: hidden;

    .slick-list {
      overflow: visible;

      .resource-dropdown {
        position: absolute;
        top: 0;
        right: 5px;
        box-shadow: 0 0 1px 1px #ddd !important;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-top: 5px !important;
        background-color: #fff;

        button {
          padding: 0 !important;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.user-name-login {
  color: #5952c6;
  font-weight: bold;
}

.flex-text-box {
}

.all-playlist {
  margin-bottom: 70px;
}

.all-playlist.check-custom {
  .check-each {
    border-bottom: 1px solid #efefef;
  }

  li {
    position: relative;
  }

  .accordion {
    background-color: #fff;
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    color: #084892;
    opacity: 1;
    border: none;
    text-transform: capitalize;
    padding: 0 0 18px;
    display: flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
    border-bottom: 2px solid #f1f1f1;
    // margin: 30px 0 32px;

    .svg-inline--fa {
      margin-right: 16px !important;
    }

    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 15px;
    }
  }

  .accordion.active + .panel {
    display: block !important;
  }

  .accordion.active i::before {
    content: '\f068';
  }

  .accordion a {
    float: right;
    text-align: left;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    color: #5952c6;
    opacity: 1;

    i {
      padding-left: 5px;

      &:before {
        content: '\f054' !important;
      }
    }
  }

  .slick-list {
    margin: 0 -5px;
  }

  .slick-slide > div {
    padding: 0 5px;
    height: 100%;
  }

  li {
    border: 1px solid #ececec;
    border-radius: 3px;
    list-style-type: none;
  }

  .playimg {
    height: 100px;
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .plydet {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    font-family: $rubik 'sans-serif';
    box-shadow: 0 0 0 transparent;
    letter-spacing: 0;
    color: $mine-shaft;
    opacity: 1;
    border: none;
    text-transform: capitalize;
    padding: 12px 16.02px;
  }
}

.back-header .go-back-button-preview,
.sce_cont .go-back-button-preview {
  // background-color: $fun-blue-1;
  display: block;
  color: rgb(127, 127, 127);
  width: fit-content;
  min-width: 190px;
  border-radius: 20px;
  text-align: end;
  font-size: 14px;
  padding: 3px 3px;
  margin-bottom: 10px;
  @media screen and (min-width: 980px) {
    padding: 3px 3px !important;
  }

  .fa {
    padding-right: 5px;
  }
}

.back-header {
  padding: 13px 35px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  a {
    background-color: #fff !important;
    margin-bottom: 0 !important;
  }

  .dropdown-menu {
    padding: 10px 20px;
    width: 240px;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 20px #00000029;
    border: 1px solid #e9e9e9;
    opacity: 1;

    li {
      i {
        opacity: 0.7;
      }

      &:hover {
        background-color: transparent;
        color: #4646c4;

        .title {
          color: #4646c4;
        }
      }

      div {
        display: flex;
        align-items: center;
      }

      .title {
        padding-left: 10px;
        font-size: 12px;
        color: #000;
        opacity: 0.7;
      }
    }
  }

  .dropdown button {
    border-radius: 50%;
    border: none;
    width: 25px;
    height: 25px;
    background-color: #dcdcdc;
    display: flex;
    justify-content: center;
    padding-top: 7px;
    align-items: center;

    i {
      color: #fff;
      font-size: 13px !important;
      padding: 0 !important;
    }

    &:focus,
    &:active,
    &:hover {
      background-color: #126bdf !important;
    }
  }

  .go-back-button-preview {
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0;
    color: #126bdf;
  }
}

.sce_cont .go-back-button-preview {
  margin-bottom: 0;
}

.sce_cont .title_lg.check {
  display: flex;
  justify-content: space-between;
}

.sce_cont .bar_list.flex-div,
.sce_cont .bar_list.flex-div li {
  width: 100%;
  margin-bottom: 0;
}

.project-dropdown.check {
  width: 100%;
  height: 100%;
}

.activity-options-wrapper.check,
.preview-dropdown.check,
.playlist-dropdown.check,
.project-dropdown.check {
  .dropdown-menu {
    min-width: 160px;
    margin: 0;
    padding: 8px;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 1px rgba(81, 81, 81, 0.16);

    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      // color: $mine-shaft;
      color: #084892;
      padding: 4px 6px;
      // sborder-bottom: 1px solid #eee;
      display: flex;
      margin-bottom: 4px;

      &:hover {
        color: #2e68bf;
        background: rgba(229, 238, 255, 0.32);
        border-radius: 4px;
      }
      .menue-img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      .fa {
        padding-right: 4px;
        font-size: 12px;
        color: $mine-shaft;
      }
    }
  }
}

.col-md-3.check {
  margin-bottom: 25px;
}

.main-page-content.preview {
  padding: 0px;

  .line::after {
    content: '';
  }

  .watcher {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0;
    color: #707070;
    padding: 20px 15px;

    span {
      color: #4646c4;
    }
  }

  .scrollDiv {
    border-right: 1px solid #989da1;
    padding-right: 0 !important;

    li.check {
      display: flex;
      border: 1px solid #ececec;
      padding: 8px 15px;

      &:hover {
        border-left: 3px solid $curriki-main-blue;
      }

      .bg-thumbnail {
        padding: 20px;
        width: 80px;
        height: 50px;
        background-position: center;
        background-size: cover;
        margin-right: 15px;
      }

      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        color: #090708;
        opacity: 1;
      }
    }
  }

  .card-body {
    //background: #314250;
    border: none;

    .scrollDiv {
      border-right: 1px solid #989da1;
      padding-right: 0 !important;

      div {
        display: flex;
        align-items: center;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        background-color: transparent;
        cursor: pointer;
        letter-spacing: 0;
        color: #ffffff;
        opacity: 0.4;
        border-bottom: 1px solid #989da1;
        padding: 15px 0;

        &:hover {
          opacity: 0.8;
        }

        i {
          font-size: 20px;
          -webkit-text-stroke: 1px #314250;
          padding-right: 10px;
        }
      }
    }
  }

  .right-sidegolf-info {
    min-height: 160px;
    padding: 0 !important;
    background-color: #fff;
    border-left: 1px solid #989da1;
    position: fixed;
    cursor: pointer;
    padding-bottom: 100px !important;
    overflow-y: auto;
    cursor: pointer;
    padding-bottom: 100px !important;
    overflow-y: auto;

    .drpdown {
      border-bottom: 1px solid #ddd;
      padding: 5px 0;
    }

    button {
      // background: transparent;
      // border: none;
      // text-align: left;
      // font-weight: 600;
      // font-size: 16px;
      // line-height: 22px;
      // letter-spacing: 0;
      // color: #FAFAFA;
      // opacity: 1;
      // width: 100%;

      span {
        opacity: 0.6;
      }

      .fa {
        font-size: 25px;
        padding-right: 6px;
      }

      &:active,
      &:focus,
      &:active {
        background: transparent;
        color: #fafafa;
        box-shadow: 0 0 0 0 transparent;
        border: none;
        outline: none;
      }
    }

    button.collapsed {
      .fa:before {
        content: '\f107';
      }
    }

    .sub-heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
    }
  }

  .main-item-wrapper {
    position: relative;
    background: #fff;
    min-height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 0;
    margin-top: 50px;
    .item-container {
      padding-bottom: 50px;
      @media screen and (max-width: 1499px) {
        margin-top: 20px;
      }
    }
  }
  .slider-scroll-auto {
    min-height: 450px;
    overflow-y: auto;
  }
  .flex-container {
    width: 100%;
    margin-top: 40px;
    z-index: 11;
    border-radius: 0px;
  }
  .activity-bg.left-vdo {
    position: relative;
    width: 100%;
    //max-height: 650px;
    //min-height: 500px;
    height: 100vh;
    padding: 0 !important;
    background-color: #fff !important;

    .vd-controls {
      height: 40px;
      background: #fff;
    }
  }
  .all-laylist-oracle {
    padding: 20px 5px;
    .preview-activity-dropdown {
      position: unset;
      align-items: center;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;
      padding: 10px 20px 4px 10px;
    }
    .resource-dropdown {
      position: unset;
    }
    li {
      list-style-type: none;
      margin-bottom: 7px;
      font-size: 14px;
      font-weight: 400;
      color: rgb(51, 51, 51);
      display: flex;
      align-items: center;
      &:hover a {
        text-decoration: none;
      }
    }
    .bg-thumbnail {
      width: 60px;
      height: 50px;
      background-size: cover;
      margin-right: 10px;
      background-position: center;
      flex-shrink: 0;
    }
  }

  .right-sidegolf-info {
    width: 40%;
    max-height: 100%;
    min-height: 100%;

    height: 100%;
  }

  .act-top-header {
    margin: 0 !important;
  }

  .right-control {
    position: initial;
    width: inherit;
    height: initial;
    background-color: #fafafa;
    display: flex;
    align-items: center;

    .slider-btn {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      i {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        color: #4646c4;
        cursor: pointer;
        -webkit-text-stroke: 1px #fff;

        &:hover {
          //@extend background: #4646c4;
        }
      }
    }

    button {
      border-radius: 50%;
      border: none;
      width: 34px;
      height: 34px;
      background-color: #dcdcdc;

      .fa {
        color: #707070;
      }

      &:hover {
        background: #4646c4;

        .fa {
          color: #fff;
        }
      }
    }
  }

  .flex-container-preview {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin: 0;
    padding: 0 20px;
    box-shadow: 0 -1px 0 #555 inset;
    align-items: center;
    position: fixed;
    width: 75%;
    top: 40px;
    z-index: 11;
    background-color: #fff;
    @media screen and (max-width: 1499px) {
      height: 70px;
    }
    h3 {
      font-size: 16px;
      font-weight: bold;
      color: #084892;
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .dropdown-menu {
      top: 8px !important;
      left: -135px !important;
      min-width: 190px;
    }
    .heading-wrapper {
      width: 100%;
    }
    .main-heading {
      text-align: left;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      padding-bottom: 0;
      letter-spacing: 0;
      color: #084892;
      opacity: 1;
      border-bottom: none;
      margin: 0 !important;
      // font-family: $oracle, sans-serif;
    }
    .slide-control {
      background: transparent;
      display: block;
      width: 100%;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0;
      color: #7b7b7b;
      opacity: 1;
      height: auto;
      // font-family: $oracle, sans-serif;
      padding: 12px 12px;
      text-transform: capitalize;
      .fa {
        padding-right: 10px;
        -webkit-text-stroke: 1px #fff;
        color: #7b7b7b;
      }
      &:hover {
        background-color: #0063d5;
        color: #fff;
        .fa {
          color: #fff;
          -webkit-text-stroke: unset;
        }
      }
    }
  }
}

#curriki-h5p-wrapper {
  .loader_gif {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 300px;
  }
}

.container-flex-upper {
  max-width: 1280px;
  margin: 0 auto 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  opacity: 0.7;
  color: #fff;

  .project-title {
    color: #fff;
  }

  svg {
    font-size: 18px;
    color: #fff;
    opacity: 1;
  }

  img {
    margin-right: 10px;
    width: 20px;
  }
}

.need_help {
  position: fixed;
  width: 120px;
  height: 50px;
  right: 80px;
  bottom: 20px;
  z-index: 1111;
  background: #007bff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  clip-path: polygon(0% 0%, 93% 0, 100% 50%, 93% 100%, 0% 100%);
  border-radius: 5px;
}

.loading-phf-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.box-unauthorized {
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 0 40px #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 330px;
  }

  h3 {
    font-size: 20px;
    padding: 30px 0 15px;
    font-weight: 500;
    text-align: center;
  }

  a {
    background-color: #4646c4;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
  }
}

.dropdown-submenu.send {
  position: relative;

  &:hover {
    .dropdown-menu.check {
      display: block;
      left: -130px;
      top: 0;
      width: 130px;
    }
  }
}

.loader-share {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #21252961;
  flex-direction: column;

  img {
    display: block;
  }

  h1 {
    color: #fff;
    padding-left: 17px;
    font-size: 20px;
    font-weight: 600;
  }
}

.swal2-title {
  font-size: 24px !important;
  font-weight: 300 !important;
  display: block !important;
  color: #084892;
}

.swal2-html-container {
  color: #084892;
  font-size: 16px !important;
  p {
    font-weight: 400 !important;
  }
}

.swal2-actions {
  button.swal2-confirm {
    order: 1;
    background-color: #084870;
    border: 2px solid #08487d !important;
  }
  button {
    border-radius: 24px !important;
    min-width: 165px;
  }
  button.swal2-cancel {
    border: 2px solid #08487d !important;
    background-color: #ffffff !important;
    color: #08487d !important;
  }
  button.swal2-deny {
    border: 2px solid #08487d !important;
    background-color: #ffffff !important;
    color: #08487d !important;
  }
  .order-1 {
    order: 3;
  }
  .order-2 {
    order: 2 !important;
  }
  .order-3 {
    order: 1;
  }
}

.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  text-transform: uppercase;
  margin-right: 20px;

  .react-switch {
    padding-left: 5px;
  }
}

.full-width-share {
  margin-top: 30px;

  .scene,
  .playlist-div {
    margin-right: 0;
  }
}

.shared-link {
  font-size: 14px;
  padding-left: 8px;
  padding-top: 10px;
  cursor: pointer;

  i {
    padding-right: 12px;
  }
}

.whole-authorized {
  background: #f8f9fa;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 11;
}

.slider-hover-section {
  position: relative;
  padding: 18px 20px;
  // width: 20px;

  // &:hover {
  //   background-color: #007bff;

  //   i {
  //     color: #fff !important;
  //     -webkit-text-stroke: 1px #007bff;
  //   }
  // }
}

.slider-hover-section {
  .hover-control-caption {
    top: 13px;
    left: -125px;
  }

  &:hover {
    .hover-control-caption {
      display: block;
    }
  }
}

.slider-end {
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    padding: 25px;
    color: #45d22a;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #007bff !important;
    border-top: 1px solid #ddd;
    padding: 15px 17px;
    text-align: left !important;
    display: block;

    i {
      display: inline-block !important;
      margin-left: 20px;
      color: #007bff !important;
    }
  }
}

.hover-control-caption.no-data {
  width: 235px;
  min-height: auto !important;
  left: -200px;
  padding: 0;
}

.hover-control-caption.no-data.prev a i {
  margin-left: 0;
  margin-right: 5px;
}

.watcher.spaner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background: #efefef;
  padding: 10px 15px !important;

  .react-switch {
    margin-left: 10px;
  }

  div,
  .shared-link {
    align-items: center;
    display: flex;
    padding-top: 0;
  }

  .google-script {
    display: none;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .inline-block {
    display: inline-block;
  }
}

.model-box-view {
  background-color: #3c3d3e00 !important;

  .modal-header img {
    max-width: 110px;
  }

  .modal-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 40px 15px;

    p {
      padding-bottom: 15px;
    }

    button span {
      width: 80px;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      font-weight: bold !important;
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }

  .classroom-form {
    padding: 0 20px;

    h1 {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 20px;
    }

    input,
    select,
    textarea {
      border: 1px solid #b5b4b4;
      padding-left: 20px;
      margin-bottom: 20px;
    }

    textarea {
      height: auto;
    }

    .form-error {
      color: red;
      font-size: 12px;
      text-align: left;
      margin-top: -20px;
    }

    button {
      background-color: #5952c6;
      color: #fff;
      width: 150px;
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
}

.footer-img-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  z-index: 1000;
  padding: 10px 30px 10px 166px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 1px 1px 1px 1px #ddd;
  justify-content: space-between;

  .footer-all {
    display: flex;
  }

  a {
    padding-right: 30px;
    color: $fun-blue-1;
    font-size: 12px;
    font-weight: 600;
    // text-decoration: underline;
    font-family: $sans;
  }

  span {
    font-size: 14px;
    color: #515151;
    margin-right: 20px;
    font-family: $sans;
  }
}

.config_options {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border: 1px solid #ccc;
  color: #5952c6;

  div {
    width: 50%;
    padding: 3px;
    text-align: center;
    cursor: pointer;

    i {
      padding-right: 3px;
    }

    &:first-child {
      border-right: 1px solid #ccc;
    }

    &:hover {
      color: #fff;
      background: #5952c6;
    }
  }
}

.model-box-video {
  .modal-dialog {
    max-width: 900px;

    img {
      max-width: 125px;
    }
  }
}

.formRegister {
  background-color: #efefef;

  .form-group {
    width: 100%;

    p {
      color: #7c98b6;
      font-size: 11px;
      padding-bottom: 5px;
    }

    label {
      font-size: 13px;
      color: #33475b;

      span {
        color: red;
      }
    }

    .error {
      font-size: 13px;
      color: red;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: 0 15px;
      height: 40px;
      border: 1px solid #cbd6e2;
      border-radius: 3px;
      background-color: #f5f8fa;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      color: #33475b;
    }

    textarea {
      height: 80px !important;
    }
  }

  button {
    position: relative;
    margin: 0;
    padding: 12px 24px;
    cursor: pointer;
    display: inline-block;
    background: #4646c4;
    border-radius: 3px;
    border: none;
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    line-height: 12px;
    text-align: center;
  }

  .half-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-group {
      width: 49%;

      @media (max-width: 767px) {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }
}

.main-page-content.preview .slider-scroll-auto {
  max-height: 420px;
  overflow-y: auto;
  min-height: 400px;
}

.main-page-content.preview {
  z-index: 1;
  //height: 105vh;
}

.confirm-page {
  .login-container {
    min-width: 400px;
  }

  .loader {
    width: 50px;
    text-align: center;
    margin: 0 auto;
    display: table;
  }

  a {
    width: 100%;
    padding: 10px 10px 10px 0;
    margin-top: 10px;
    display: flex;
    justify-content: left;
    border: none;
    font-size: 19px;
    color: #4646c4 !important;
    cursor: pointer;
  }

  h2 {
    line-height: 30px;
    display: block;
  }

  h3 {
    display: block;
  }
}

.activity-item.with_options {
  min-height: 240px !important;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between !important;
  padding-top: 20px;
  .content {
    cursor: pointer;
  }

  .question-label {
    margin-bottom: 0;
  }

  .option_section {
    width: 100%;
    display: flex;
    border-top: 1px solid #ccc;

    .option_type {
      width: 50%;
      padding: 5px;
      color: #084892;
      text-transform: capitalize;

      &:hover {
        background-color: #084892;
        color: #fff;
      }

      &:first-child {
        border-right: 1px solid #ccc;
      }

      i {
        padding-right: 3px;
      }
    }
  }
}

.video_activity.modal {
  .modal-xl {
    max-width: 1440px !important;
    width: 100% !important;
  }
  nav {
    margin: 0;
    padding: 0 0 0 0;
    border: none;
    margin-bottom: 15px;

    a.active {
      border-bottom: 2px solid #f8af2c;
      color: #084892;
      font-weight: 500;
    }

    a {
      color: #084892;
      font-weight: 400;
      border: none;
      padding: 0px 45px;
      border-bottom: 1px solid #084892;
    }
  }

  .modal-title img {
    width: 80px;
  }

  iframe {
    width: 100%;
    min-height: 500px;
  }

  .modal-body {
    padding: 20px;
  }
}

.stayTuned {
  padding: 150px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    padding-bottom: 10px;
    font-size: 40px;
    font-weight: 600;
    color: #4646c4;
  }

  h5 {
    font-size: 16px;
    font-weight: 300;
  }
}

//activity upload css

.flex-form-imag-upload {
  // width: 100%;
  // display: flex;
  align-items: center;
  justify-content: space-between;

  .leftdata {
    width: 80%;
  }
}

.upload-thumbnail.check {
  label {
    display: none;
  }

  .upload_placeholder {
    width: 400px;
    display: flex;
    cursor: pointer;

    .gallery,
    .pexel {
      width: 100%;
      height: 140px;
      display: flex;
      border: 1px solid #ccc;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        font-weight: 300;
        font-size: 14px;
        color: #084892;
      }
    }
  }

  .thumb {
    cursor: pointer;
    margin-right: 15px;
  }

  .fa {
    font-size: 40px;
  }
}

.modelbox-container-text {
  padding-bottom: 15px;
  font-size: 16px;
}

.registration-body-page #hubspot-messages-iframe-container {
  display: none !important;
}

.create-project .fa-plus-circle {
  font-size: 30px;
  color: #084892;
}

.top-header.flex-div .dropdown-toggle {
  cursor: pointer;
}

.top-header.flex-div .dropdown-toggle::after {
  content: none;
  cursor: pointer;
}

.create-project .dropdown-menu.user-dropdown {
  min-width: 400px !important;
  top: 11px !important;
  left: 0 !important;
  padding: 15px;
  border-top: 0;
}

.menu-user-settings .dropdown-menu.user-dropdown {
  top: 10px !important;

  padding: 15px;
  border-top: 0;
}

.mobile-app-alert {
  display: none;
}

@media (max-width: 991px) {
  .mobile-app-alert {
    background-color: #eee;
    color: #333;
    height: 100%;
    width: 100%;
    z-index: 111111111;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    overflow-y: auto;

    img {
      width: 175px;
      margin-bottom: 10px;
    }

    .text-description {
      background-color: #fff;
      padding: 35px 20px;
    }

    h2 {
      font-weight: Bold;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0;
      color: $curriki-main-blue;
      text-transform: uppercase;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0;
      color: $mine-shaft;
    }

    .reg-btn {
      width: 100%;
      min-width: 150px;
      height: 40px;
      background: $curriki-main-blue 0 0 no-repeat padding-box;
      border-color: $curriki-main-blue;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 0.76px;
      color: #ffffff;
      opacity: 1;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    }
  }

  .mobile-responsive .mobile-app-alert {
    display: none;
  }
}

.iframe-height-resource-shared .main-item-wrapper.desktop-view {
  overflow: visible !important;
}

.shared-template {
  .navbar {
    margin: 0;
    width: 100%;
  }

  .slick-slider {
    background: #fff;
    box-shadow: 0px 0px 17px 0px #ccc;
    padding: 15px 15px 10px;
    border-radius: 10px;
  }

  .bg-img1 {
    width: 170px;
  }

  .navbar-nav {
    justify-content: flex-end;
    width: 100%;
  }

  .custom-container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 80px;
  }

  .box {
    border-color: #ececec;
    box-shadow: 0px 2px 18px -5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    height: 400px;
    margin-bottom: 30px;
    overflow-y: auto;
  }

  .box img {
    width: 100%;
    border-radius: 10px;
  }

  .box h1 {
    color: #4472c4;
    font-size: 22px;
    font-weight: 600;
    line-height: 100%;
    padding: 10px 15px;
    margin-bottom: 0px;
  }

  .box h2 {
    color: #333;
    font-size: 14px;
    font-weight: 300;
    line-height: 100%;
    padding: 0px 15px;
    margin-bottom: 0px;
    font-weight: 600;
  }

  .box h2 span {
    text-decoration: underline;
    font-weight: 400;
  }

  .box p {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    padding: 20px 15px;
    margin-bottom: 0px;
    line-height: 20px;
  }

  .box p span {
    color: #4472c4;
    text-decoration: underline;
  }

  .responsive {
    //padding: 50px 20px;
    // background-image: url('images/bg.jpg');
    margin-bottom: 30px;
  }
  .responsive .box {
    height: 210px;
    background-color: #fff !important;
    margin-bottom: 0px;
    margin: 0px 5px;
  }

  .responsive .box .bg-img {
    height: 125px;
  }

  .responsive .box h1 {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }

  // .slick-slide {
  //     margin: 0 10px;
  // }
  // /* the parent */
  // .slick-list {
  //     margin: 0 -10px;
  // }

  .nav-item a {
    font-weight: 700;
    font-size: 16px;
    color: #0c71c3 !important;
  }

  .dropdown-menu {
    padding: 10px;
    width: 280px;
  }

  .dropdown-menu a {
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    width: 100%;
    display: block;
    padding: 3px 0px;
  }

  .bg-img {
    height: 200px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  .slick-next {
    right: 12px;
  }

  .slick-prev {
    left: 5px;
    z-index: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
    -webkit-text-stroke: 0.8px #333;
  }

  .text-sup {
    font-size: 16px;
    padding: 20px 10px;
    margin-bottom: 0px;
  }

  .flex-shared-slider {
    display: flex;
    flex-wrap: wrap;

    .boxer {
      width: 25%;
      padding: 10px;
      margin-bottom: 10px;

      @media (max-width: 1199px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .bax {
      background-color: #fff;
      min-height: 450px;

      h1 {
        color: #4472c4;
        font-size: 22px;
        font-weight: 600;
        line-height: 100%;
        padding: 10px 15px;
        margin-bottom: 0px;
      }

      h2 {
        color: #333;
        font-size: 14px;
        font-weight: 300;
        line-height: 100%;
        padding: 0px 15px;
        margin-bottom: 0px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        padding: 20px;
        color: #333;
      }
    }
  }
}

.text-added-project-dropdown {
  button {
    width: 100%;
    padding-left: 0px !important;
    height: 28px;
    //  border-radius: 0px;
    //  border: 1px solid #ece9e9 !important;
    //  width:33%;
    span {
      font-size: 12px;
      line-height: 16px;
      color: #084892 !important;
      padding-left: 5px !important;
      font-weight: bold;
    }
    &:hover {
      background-color: #084892 !important;
      span,
      svg {
        color: #ffffff !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-page-content.preview {
    .both-p,
    .act-top-header {
      display: none !important;
    }
    .container-flex-upper,
    .flex-container-preview {
      justify-content: flex-end;

      .slider-hover-section {
        position: relative;
        padding: 18px 10px;
      }
    }
  }
  .flex-container.previews .activity-bg.left-vdo {
    .h5p-iframe-wrapper,
    .flex-container-preview {
      width: 85%;
    }
  }
  .iframe-height-resource-shared {
    .h5p-iframe-wrapper {
      width: 100% !important;
    }
  }
  .flex-container.previews .activity-bg.left-vdo.collapsed {
    .h5p-iframe-wrapper,
    .flex-container-preview {
      width: 50%;
    }
  }
  .flex-container.previews .right-sidegolf-info {
    width: 15% !important;
    right: 0;
    top: 40px;
  }
  .flex-container.previews .right-sidegolf-info.collapsed {
    width: 50% !important;
    right: 0;
    top: 40px;
  }
}

.loader-main-curriki-permission {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1111111;
  color: #222;
  top: 0;
  left: 0;
  flex-direction: column;

  img {
    display: block;
  }

  .logo {
    width: 200px;
  }

  .loader {
    width: 45px;
  }
}

body.collapsed {
  .main-content-wrapper {
    .assign-projects .assign-projects-content {
      margin-left: 100px;
    }
    // .side-wrapper-team {
    //   padding-left: 160px;
    //   .bread-crumb {
    //     position: initial;
    //   }
    // }
    .side-wrapper-group {
      left: 110px;
      .bread-crumb {
        position: initial;
      }
    }
  }
  .sidebar-wrapper {
    // width: 93px;
    aside {
      .toggleButton {
        transform: rotate(180deg);
      }
    }
  }
  .content-wrapper {
    margin-left: 136px !important;
    padding: 25px;
  }
}

.helpcenter {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  z-index: 1111;
  background: #084892ad;

  .content {
    width: 430px;
    background: #fff;
    height: 100%;
    overflow-y: auto;
    margin: 0;
  }

  .heading-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    border-bottom: 1px solid #ccc;

    h2 {
      font-weight: 500;
      font-size: 20px;
      padding: 0;
      margin: 0;
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .all-help {
    .box {
      display: flex;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;

      &:hover {
        background: #e5eeff;
      }
    }

    img {
      width: 69px;
      height: 69px;
      margin-right: 22px;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }

    .detail {
    }
  }
}

.iframe-height-resource-shared.defaultcontainer {
  .flex-container.previews {
    max-width: 65% !important;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .main-item-wrapper {
      width: 100%;
      margin-bottom: 0;
    }

    @media (max-width: 1280px) {
      padding: 0px;
    }
    @media (max-width: 768px) {
      max-width: 95% !important;
    }
  }
  @media (orientation: landscape) and (max-width: 768px) {
    max-width: 70% !important;
    margin: 0 auto;
  }
  .activity-bg.left-vdo {
    height: auto !important;
  }
  .item-container,
  .h5p-iframe-wrapper {
    padding-bottom: 0 !important;
  }
}

.coverallareas {
  display: none;
  @media (max-width: 991px) {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111;
    background: #f8f9fa;
    top: 0;
  }
}

//toasitify styles
.project-loading {
  background: $fun-blue-1 !important;
  color: #fff !important;
  box-shadow: 0 1px 10px 0 #000000 !important;

  .Toastify__progress-bar {
    background: #fff !important;
  }
}

.customcard60 .playlist-resource {
  max-width: 16% !important;
  width: 16% !important;
}

.customcard50 .playlist-resource {
  max-width: 25% !important;
  width: 25% !important;
}

.customcard30 .playlist-resource {
  max-width: 33% !important;
  width: 33% !important;
}

.terms-policy-container {
  background: #fff;
  padding: 32px 30px 30px;
  margin: 36px;
  border-radius: 3px;
}

.swal2-icon {
  width: 2.5em !important;
  height: 2.5em !important;
  .swal2-icon-content {
    font-size: 2.5em !important;
  }
}

.swal2-icon.swal2-success {
  .swal2-success-line-tip {
    top: 1.4em !important;
    left: 0.3em !important;
    width: 0.9em !important;
    background-color: #12b347 !important;
  }
  .swal2-success-line-long {
    top: 1.09em !important;
    right: 0.07em !important;
    width: 1.8em !important;
    background-color: #12b347 !important;
  }
  .swal2-success-fix {
    width: 0;
    height: 0;
  }
  .swal2-success-ring {
    border: 0.25em solid #12b347 !important;
  }
}

.swal2-icon.swal2-warning {
  border-color: #f8af2c !important;
  color: #f8af2c !important;
}

.swal2-icon.swal2-error {
  .swal2-x-mark-line-left {
    top: 1.1em !important;
    width: 1.5em !important;
    left: 0.5em !important;
  }
  .swal2-x-mark-line-right {
    top: 1.1em !important;
    width: 1.5em !important;
    left: 0.5em !important;
  }
}

.confirmation-close-btn {
  background: #ffffff !important;
  color: #084892 !important;
  border: 1px solid #084892 !important;
  width: 177px;
  height: 32px;
  padding: 0 !important;
}
.swal2-cancel,
.swal2-confirm,
.swal2-deny {
  height: 32px;
  padding: 0 !important;
}
.video_activity .interactivevideoreferce {
  margin: 0 auto;
  min-height: calc(100vh - 200px);
}
.footer-img-section .img- img {
  width: 71px;
  height: 40px;
  object-fit: contain;
}
.model-box-google img {
  max-width: 100%;
}
.model-box-google .model-body {
  max-width: 100% !important;
}